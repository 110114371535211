<template>
    <div 
        class="contact"
        :style="[isLanding ? {
            'background-image': 'url(' + usbg + ')',
            'background-repeat': 'no-repeat',
            'background-size': '100%',
            'background-position': 'top'
        } : {}]"
    >
        <div class="contact-wrapper container">
            <h2>{{ $t('contact_us') }}</h2>

            <h3>We Would love to hear from you.</h3>

            <div class="items-wrapper">
                <div class="item">
                    <img :src="require(`@/assets/img/location_icon.png`)" alt="location" class="icon pin">
                    <p class="label">Address</p>
                    <p class="content">Taison Digital Ltd. Unit 29, 18/F, Block B, Wah Lok Ind. Centre, 37－41 Shan Mei Street, Fo Tan, Shatin, N.T. </p>
                </div>

                <div class="item">
                    <img :src="require(`@/assets/img/email_icon.png`)" alt="number" class="icon">
                    <p class="label">Email us at</p>
                    <p class="content">iamsupport@findlinc.com</p>
                    
                    <div class="vat">
                        <p class="label">Vat Number: </p>
                        <p class="content">24780879</p>
                    </div>
                </div>

                <div class="item">
                    <img :src="require(`@/assets/img/mobile_icon.png`)" alt="email" class="icon">
                    <p class="label">Contact us at</p>
                    <p class="content">(852) 23699788</p>
                </div>
            </div>

            <!-- <div class="form-container">
                <p class="title">{{ $t('get_in_touch') }}</p>

                <form @submit.prevent="contact()" class="contact-form">
                    <div class="wrapper name">
                        <div class="input-form fname">
                            <label for="fname">{{ $t('first_name') }}</label>
                            <input
                                id="fname"
                                type="text"
                                :placeholder="$t('first_name')"
                            />
                        </div>

                        <div class="input-form">
                            <label for="lname">{{ $t('last_name') }}</label>
                            <input
                                id="lname"
                                type="text"
                                :placeholder="$t('last_name')"
                            />
                        </div>
                    </div>
                    
                    <div class="wrapper">
                        <div class="input-form">
                            <label for="email">{{ $t('email_address') }}</label>
                            <input
                                id="email"
                                type="email"
                                :placeholder="$t('email_address')"
                            />
                        </div>
                    </div> -->

                    <!-- <div class="wrapper">
                        <div class="input-form">
                            <label for="reason">Reason / Problem</label>
                            <input
                                id="reason"
                                type="text"
                                placeholder="Your First Name"
                            />
                        </div>
                    </div> -->

                    <!-- <div class="wrapper">
                        <div class="input-form">
                            <label for="feedback">{{ $t('write_here') }}</label>
                            <textarea
                                name="feedback"
                                id="feedback"
                                cols="30"
                                rows="10"
                                :placeholder="$t('what_do_you_think')"
                            />
                        </div>
                    </div>

                    <button type="submit" class="primary">
                        {{ $t('submit') }}
                    </button>
                </form>
            </div> -->
        </div>
    </div>
</template>

<script>
export default {
    props: {
        isLanding: {
            type: Boolean,
            default: true
        }
    },

    data () {
        return {
            lower: require('@/assets/img/home_lowerbg.png'),
            usbg: require('@/assets/img/contact_usbg.png')
        }
    },

    methods: {
        contact () {
            console.log('submit')
        }
    }
}
</script>

<style lang="scss" scope>
.contact {
    padding-bottom: 40px;
    // background-image: url('~@/assets/img/home_lowerbg.png'), url('~@/assets/img/contact_usbg.png');
    // background-repeat: no-repeat;
    // background-size: 100%;
    // background-position: bottom, top;

    .contact-wrapper {
        // width: 1200px;

        @include desktop {
            width: 1200px;
        }

        h2 {
            text-align: center;
            font-family: 'Crimson Pro', sans-serif;
            font-weight: 700;
            font-size: 34px;
            padding: 50px;
        }

        h3 {
            text-align: center;
            font-family: 'Josefin Sans', sans-serif;
            font-weight: 600;
            font-size: 32px;
            margin-bottom: 35px;
        }

        .items-wrapper {
            display: flex;
            justify-content: center;

            .item {
                display: flex;
                flex-direction: column;
                align-items: center;
                width: 195px;
                margin-right: 75px;

                @include desktop {
                }

                img {
                    width: 32px;
                    height: 32px;
                    margin-bottom: 15px;

                    &.pin {
                        width: 23px;
                    }
                }

                .label {
                    font-size: 15px;
                    font-weight: 600;
                    margin-bottom: 10px;
                }

                .content {
                    font-family: 'Josefin Sans', sans-serif;
                    text-align: center;
                    color: #AFAFAF;
                    font-size: 13px;
                }

                .vat {
                    margin-top: 10px;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    .label {    
                        margin-right: 8px;
                        margin-bottom: 0;
                        font-size: 16px;
                    }

                    .content {
                        margin-top: 4px;
                    }
                }
            }
        }

        // .form-container {
        //     background-color: #FFFFFF;
        //     border-radius: 8px;
        //     padding: 15px 20px;
        //     box-shadow: 0px 20px 60px #3E3E3E14;

        //     @include desktop {
        //         padding: 50px 100px;
        //     }

        //     .title {
        //         font-family: 'Josefin Sans', sans-serif;
        //         font-weight: 600;
        //         font-size: 32px;
        //         text-align: center;
        //         margin-bottom: 40px;
        //     }

        //     .contact-form {
        //         text-align: center;

        //         @include desktop {
        //             text-align: left;
        //         }
        //     }

        //     .wrapper {
        //         margin-bottom: 35px;

        //         &.name {
        //             display: flex;
        //             justify-content: center;
        //             flex-direction: column;

        //             @include desktop {
        //                 flex-direction: row;
        //             }

        //             div {
        //                 flex: 1;

        //                 &.fname {
        //                     margin-bottom: 35px;

        //                     @include desktop {
        //                         margin-bottom: 0px;
        //                         margin-right: 50px;
        //                     }
        //                 }
        //             }
        //         }

        //         .input-form {
        //             display: flex;
        //             flex-direction: column;

        //             label {
        //                 text-align: left;
        //                 font-weight: 600;
        //                 font-size: 15px;
        //                 color: #221E1F;
        //                 margin-bottom: 18px;
        //             }

        //             input, textarea {
        //                 font-family: 'Josefin Sans', sans-serif;
        //                 font-size: 13px;
        //                 padding-bottom: 20px;
        //                 border: 0;
        //                 border-bottom: 1px solid #E5E5E5;
        //                 color: #AFAFAF;
        //                 resize: none;

        //                 &:focus {
        //                     outline: none;
        //                 }
        //             }
        //         }
        //     }
        // }
    }
}
</style>